@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Encode+Sans+Expanded");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Encode Sans Expanded", sans-serif;
}

.logo-text {
  font: "Josefin Sans";

}

.center-align {
  text-align: center;
}

.black-bg {
  background: black;

}

.white-bg {
  background: white;

}

.primary-bg {
  background: #B05FFD;

}

.h1-hero {
  color: #fff;
  font-size: 48px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

}

.hero-text {
  color: #fff;
  font-size: 24px;
  text-align: center;

}

.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;

}

.fit-img {
  width: 100%;

}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}

textarea {
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
  border: solid gray 1px;
}
.pride{
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 10px 10px;
  color: #B05FFD;;
}
.alert-success{
  margin-top: 10px;
  border-radius: 10px;
}
.copy-right {
  padding-top: 10px;
}